import { useState, useEffect } from 'react';
import { ethers } from "ethers";
import erc20abi from "./ERC20abi.json";
import './style.css';
import bg from './bg.png';
import waterrune from './waterrune.png';



export default function App() {

  const [walletAddress, setWalletAddress] = useState("");


  const address = "0x2CD7d41be529d76fF3Cac2074F8829326f6f511B";
  // const address = "0x96fb2a6E6B419a0244fdCF76EC811C5bf0800852"; FTM

  const [JefesInfo, setJefesInfo] = useState({
    time: "-"
  });
  //    const url = "https://bsc-dataseed.binance.org/";

  const provider = new ethers.providers.JsonRpcProvider("https://rpc.sonic.fantom.network/");
  //  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const erc20 = new ethers.Contract(address, erc20abi, provider);
  const signer = provider.getSigner();




  //wallet connect
  async function requestAccount() {
    console.log('Requesting account ...')
    //Check if Metamask exists
    if (window.ethereum) {
      console.log('detected');
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
      } catch (error) {
        console.log('Error Connecting ..');
      }
    } else {
      console.log('Metamask not detected');
    }
  }


  //get time function 

  const getJefes = async (e) => {
    e.preventDefault();
    const releaseStartDate = await erc20.getMyJefes(walletAddress);
    setJefesInfo({
      time: String(releaseStartDate)
    });
  };

  const beanRewards = async (e) => {
    e.preventDefault();
    const addresswallet = await erc20.beanRewards(walletAddress);
    setJefesInfo({
      timereward: String(addresswallet)
    });
  };



  const sellJefes = async (e) => {
    e.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const erc20 = new ethers.Contract(address, erc20abi, signer);

    await erc20.sellJefes();



  };
  const hatchJefes = async (e) => {
    e.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const erc20 = new ethers.Contract(address, erc20abi, signer);
    const referido = walletAddress;

    await erc20.hatchJefes(referido);



  };


  const handleTransfer = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    console.log(data);
    const referido = walletAddress;
    const amountinvest = e.target[0].value;
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, erc20abi, signer)

    await contract.buyJefes(referido, { value: ethers.utils.parseEther(amountinvest)});

  };

  return (
    <div className='react--content'>
      <nav class="navbar navbar-dark bg-dark">
        <div className='container'>
<h1><b>WEB3 ADDRESS</b></h1>
          <button onClick={requestAccount}
            className="btn--connect btn-primary btn--landing">
            <b>CONNECT</b>
          </button>
          <h6 className='text--wallet'>{walletAddress}</h6>
        </div>
      </nav>
      <div className='container'>
        <section className='section--bg'>
        </section>

        <div className='row'>

          <div className='col'>
            <div className='overview'>
              <div align='center'>
                <h3>WATER RUNE CRAFTER</h3>
		<img src={waterrune}/>
                <h5>{JefesInfo.time}</h5>
                <br></br>
                <button
                  onClick={getJefes}
                  type="submit"
                  className="btn--connect btn-primary btn--landing">
                  SPAWN RUNES
                </button>
                <br></br>
                <br></br>
                <div align="justify">
                  <p>
               Enter JEFE RUNES, a web3 rune game with decentralized interaction on the Sonic Fantom blockchain, here you craft runes of water trough water rune crafter, ascend in the ecosystem by learning skills in web3. Face challenges, forge alliances & use ancient runes to conquer the web3 multiverse world. Your journey begins in this enchanting world of rune web3 crafting. </p><br></br>
 <p>                   
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='overview'>
              <div align='center'>
                <h3>CRAFT & ASCEND</h3>
                <br></br>
                <form onSubmit={handleTransfer}>
                  <input
                    type="text"
                    name="amount"
                    className="#"
                    placeholder="Liquid FTM / SONIC" />
                  <br></br>
                  <br></br>
                  <button
                    type="submit"
                    className="btn--connect btn-primary btn--landing">
                    CRAFT
                  </button>
                </form>
                <br></br>
                <div align="justify">
                  <p>

Craft the Rune of Fire using the Fire Rune Crafter. Respawn and intensify production in the SonicWater Challenge. Ascend the rank leaderboard, forming alliances to conquer the world of web3. Your journey blazes brighter with each crafted rune, an on chain testament of the web3  spirit in the radiant realm of JEFE RUNES.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='overview'>
              <div className='connect--wallet' align="center">
		<h3>ORIGIN</h3>
<br></br>
<div align="center">
<p><i>Add network in your  crypto wallet.</i> <a href="https://docs.jefetoken.com" target="_blank" className="text--wallet">Documentation</a> </p>
<p><b>Testnet</b></p>
<br></br>

<button className="btn--connect btn-primary btn--landing">
<a href="https://waterf.jefetoken.com" target="_blank"><b><p><font color="white">WATER RUNE FTM MAINNET</font></p></b></a>
</button><br></br><br></br>

Blockchain: Sonic Fantom<br></br>
RPC: https://rpc.sonic.fantom.network/<br></br>
CHAIN ID: 64165 <br></br>
<a href="https://sonicscan.io" target="_blank" className="text--wallet">EXPLORER</a><br></br><br></br>
<a href="https://public-sonic.fantom.network/" target="_blank" className="text--wallet">TESTNET FREE FTM HERE</a><br></br>
</div>

                <h6 className='text--wallet'> {walletAddress}</h6>
                <br></br>
                <button onClick={requestAccount}
                  className="btn--connect btn-primary btn--landing">
                  <b>CONNECT</b>
                </button>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>

        </div>

        <br></br>
        <br></br>

        <div className='row'>

          <div className='col'>
            <div className='overview'>
              <div align="center">
                <h3>IGNITE RUNES</h3>
                <br></br>
                <form onSubmit={sellJefes}>
                  <button
                    type="submit"
                    className="btn--connect btn-primary btn--landing">
                    SPARK
                  </button>
                </form>
                <br></br>
                <br></br>
                <div align="justify">
                  <p>
Ignited runes are sent back to the runes smart contract for future summoning & spawning. 
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='overview'>
              <div align="center">
                <h3>RESPAWN RUNES</h3>
                <br></br>
                <form onSubmit={hatchJefes}>
                  <button
                    type="submit"
                    className="btn--connect btn-primary btn--landing">
                    SUMMON
                  </button>
                </form>
                <br></br>
                <br></br>
                <div align="justify">
                  <p>

Respawning runes ignites & summons new runes, boosting production rates.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}
